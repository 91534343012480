/* App.css */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  background-color: #1f4037;
  overflow: hidden;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: #fff;
}

h1 {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 2rem;
}

.input-field {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

#map {
  width: 100%;
  max-width: 1000px;
  height: 500px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#confirm-btn {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
  transition: background-color 0.3s ease;
}

#confirm-btn:hover {
  background-color: #0056b3;
}

/* Responsividade */
@media (max-width: 768px) {
  #map {
    height: 300px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .auth-modal {
    background-color: white;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 360px;
    position: relative;
    max-height: 85vh; /* Limitar a altura a 85% da altura da viewport */
    overflow-y: auto; /* Permitir rolagem dentro do modal */
  }
  

  /* Ajustar inputs responsivos */
  .password-input-group input {
    width: 100%; /* Garantir que o campo ocupe todo o espaço disponível */
    padding-right: 45px; /* Espaço suficiente para o ícone de visibilidade */
  }
  

  .password-toggle {
    right: 10px;
  }
}

/* Estilos do Modal de Autenticação */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Permitir rolagem apenas dentro do modal */
  touch-action: none; /* Prevenir rolagem do background */
}


.auth-modal {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 360px;
  position: relative;
  max-height: 90vh; /* Limitar altura do modal */
  overflow-y: auto; /* Permitir rolagem dentro do modal */
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #333;
}

.auth-modal h2 {
  margin-bottom: 15px;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.auth-modal p {
  text-align: center;
  color: #666;
  margin-bottom: 25px;
  font-size: 16px;
}

.auth-modal form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 12px;
}

.auth-modal input {
  width: 100%;
  padding: 10px 12px; /* Levemente reduzido para melhorar o alinhamento */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
}

.auth-modal input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  outline: none;
}

.login-btn {
  padding: 12px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #3a7bc8;
}

.divider {
  text-align: center;
  margin: 25px 0;
  position: relative;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background-color: white;
  padding: 0 10px;
  position: relative;
  color: #666;
  font-size: 14px;
}

.google-login-container {
  display: flex;
  justify-content: center;
}

.toggle-mode {
  text-align: center;
  color: #4a90e2;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
}

.toggle-mode:hover {
  text-decoration: underline;
}

/* Estilos para as notificações */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.notification.success {
  background-color: #4CAF50;
}

.notification.error {
  background-color: #f44336;
}

.notification.info {
  background-color: #2196F3;
}

/* Estilo para a mensagem de email enviado */
.email-sent-message {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #e8f5e9;
  border-radius: 8px;
  color: #2e7d32;
  font-size: 16px;
  line-height: 1.5;
}

/* Estilos atualizados para o componente PhoneInput */
.phone-input-container {
  position: relative;
}

.react-tel-input .form-control {
  width: 100% !important;
  padding: 12px 15px 12px 50px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.react-tel-input .form-control:focus {
  border-color: #4a90e2 !important;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2) !important;
  outline: none;
}

.react-tel-input .selected-flag {
  border-radius: 8px 0 0 8px !important;
  background-color: #f5f5f5 !important;
}

.react-tel-input .country-list {
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  margin-top: 5px;
  width: 300px !important;
  border: none !important;
}

.react-tel-input .country-list .country {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;
}

.react-tel-input .country-list .country:hover {
  background-color: #f0f8ff;
}

.react-tel-input .country-list .country-name {
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.react-tel-input .country-list .dial-code {
  color: #666;
  font-size: 14px;
}

.react-tel-input .search-box {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #f5f5f5;
}

.react-tel-input .search-box input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.react-tel-input .search-box input:focus {
  border-color: #4a90e2;
  outline: none;
}

/* Estilo para o texto "Buscar país" e "Nenhum resultado encontrado" */
.react-tel-input .search-box::placeholder,
.react-tel-input .no-entries-message {
  color: #999;
  font-size: 14px;
}

/* Estilizando a barra de rolagem */
.react-tel-input .country-list::-webkit-scrollbar {
  width: 8px;
}

.react-tel-input .country-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.react-tel-input .country-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilos para os requisitos de senha */
.password-requirements {
  margin-top: 5px;
  font-size: 0.9em;
  color: #666;
}

.password-requirements p {
  margin-bottom: 5px;
  font-weight: bold;
}

.password-requirements ul {
  list-style-type: none;
  padding-left: 0;
}

.password-requirements li {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}

.password-requirements li::before {
  content: "•";
  color: #ff4d4d;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-right: 5px;
}

.requirement-met::before {
  color: #4CAF50 !important;
}

.requirement-not-met {
  color: #ff4d4d;
}

/* Estilos para o campo de senha */
.password-input-group {
  margin-bottom: 1px; /* Ajuste a margem inferior conforme necessário */
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-group input {
  width: 100%;
  padding-right: 45px; /* Espaço para o ícone de visibilidade */
  padding: 10px 12px; /* Redução semelhante ao resto dos inputs */
  box-sizing: border-box;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}


.password-toggle:focus {
  outline: none;
}

.password-toggle svg {
  width: 20px;
  height: 20px;
}

/* Estilo para o botão desabilitado */
.login-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
